import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Logo() {
  const data = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "static/logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const logo = getImage(data.file.childImageSharp)

  return <GatsbyImage image={logo} alt="Studio Kathan logo" />
}
