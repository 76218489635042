// extracted by mini-css-extract-plugin
export var bannerContent = "layout-module--banner-content--m-faf";
export var content = "layout-module--content--ayx5z";
export var footer = "layout-module--footer--vmDQL";
export var footerForm = "layout-module--footer-form--fmkSZ";
export var footerFormWrapper = "layout-module--footer-form-wrapper--gd5f0";
export var footerInput = "layout-module--footer-input--GhoUb";
export var footerLegal = "layout-module--footer-legal--2rYM7";
export var footerLogo = "layout-module--footer-logo--GQ3hE";
export var footerLogoCopy = "layout-module--footer-logo-copy--zmabi";
export var footerLogoWrapper = "layout-module--footer-logo-wrapper--lFXII";
export var footerNav = "layout-module--footer-nav--NAgid";
export var footerRight = "layout-module--footer-right--HvLFW";
export var footerRow = "layout-module--footer-row--LZ4q8";
export var layout = "layout-module--layout--trDhQ";