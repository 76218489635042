import React from "react"
import TriangleIcon from "./TriangleIcon"

import {
  button,
  buttonColored,
  buttonUnderline,
  icon,
} from "../styles/button.module.css"

export default function Button({
  type,
  children,
  colored,
  underline,
  onClick,
}) {
  return (
    <button
      type={type}
      className={`
		    ${button} ${colored ? buttonColored : ""}
		    ${underline ? buttonUnderline : ""}
		`}
      onClick={onClick}
    >
      {children}
      <span className={icon}>
        <TriangleIcon />
      </span>
    </button>
  )
}
