import React, { useState } from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import useMediaQuery from "../hooks/useMediaQuery"

import Button from "./Button"
import Head from "./Head"
import Logo from "./Logo"

import {
  layout,
  content,
  bannerContent,
  footer,
  footerRow,
  footerRight,
  footerFormWrapper,
  footerForm,
  footerInput,
  footerNav,
  footerLogoWrapper,
  footerLogo,
  footerLogoCopy,
  footerLegal,
} from "../styles/layout.module.css"

import "../styles/global.css"

export default function Layout({ children }) {
  const isMobile = useMediaQuery("(max-width: 900px)")

  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  const currentYear = new Date().getFullYear()

  const toggleNav = () => {
    setIsMobileNavVisible(!isMobileNavVisible)
  }

  function DesktopHeader() {
    return (
      <header className="main-header">
        <nav className="main-nav main-nav--left">
          <ul>
            <li>
              <Link to="/photo" activeClassName="active" partiallyActive={true}>
                Photo
              </Link>
            </li>
            <li>
              <Link
                to="/art-design"
                activeClassName="active"
                partiallyActive={true}
              >
                Art — Design
              </Link>
            </li>
            <li>
              <Link to="/news" activeClassName="active">
                News
              </Link>
            </li>
          </ul>
        </nav>

        <Link to="/" className="main-header__logo">
          {/* <img src="logo.png" alt="Studio KATHAN logo" /> */}
          <Logo />
        </Link>

        <nav className="main-nav main-nav--right">
          <ul>
            <li>
              <a
                href="https://shop.studiokathan.com/"
                target="_blank"
                rel="noreferrer"
              >
                Shop
              </a>
            </li>
            <li>
              <Link to="/about" activeClassName="active">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName="active">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }

  function MobileHeader() {
    return (
      <header className="main-header--mobile">
        <button
          className={`nav-toggle hamburger hamburger--collapse ${
            isMobileNavVisible ? "is-active" : ""
          }`}
          type="button"
          aria-label="toggle navigation"
          onClick={toggleNav}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

        <Link to="/" className="main-header__logo">
          <Logo />
        </Link>

        <nav
          className={`main-nav--mobile ${isMobileNavVisible ? "visible" : ""}`}
        >
          <ul>
            <li>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </li>
            <li>
              <Link to="/photo" activeClassName="active" partiallyActive={true}>
                Photo
              </Link>
            </li>
            <li>
              <Link
                to="/art-design"
                activeClassName="active"
                partiallyActive={true}
              >
                Art — Design
              </Link>
            </li>
            <li>
              <Link to="/news" activeClassName="active">
                News
              </Link>
            </li>
            <li>
              <a
                href="https://shop.studiokathan.com/"
                target="_blank"
                rel="noreferrer"
              >
                Shop
              </a>
            </li>
            <li>
              <Link to="/about" activeClassName="active">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName="active">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }

  return (
    <section className={layout}>
      <Head />

      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="sk-gdpr-google-analytics"
        style={{ background: "#dfdfdf", padding: "3px" }}
        buttonStyle={{
          background: "#1b1b1b",
          color: "#fff",
          fontSize: "14px",
          paddingTop: "10px",
          paddingBottom: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
        expires={1}
      >
        <div className={bannerContent}>
          <span>
            We use cookies to enhance your experience on our website and to help
            us understand how our site is used.
          </span>

          <Link to="/cookie-policy">Cookie policy</Link>
        </div>
      </CookieConsent>

      {isMobile ? MobileHeader() : DesktopHeader()}

      <div className={content}>{children}</div>
      <footer className={`${footer} container`}>
        <section className={footerRow}>
          <section className={footerFormWrapper}>
            <form
              className={footerForm}
              name="newsletter"
              method="POST"
              action="/thank-you"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="newsletter" />
              <input
                className={footerInput}
                placeholder="Email address "
                type="email"
                name="email"
                required
              />
              <Button type="submit">Subscribe</Button>
            </form>
          </section>
          <section className={footerRight}>
            <nav className={footerNav}>
              <h3>Work</h3>
              <ul>
                <li>
                  <a
                    href="https://shop.studiokathan.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Shop
                  </a>
                </li>
                <li>
                  <Link to="/photo">Photo</Link>
                </li>
                <li>
                  <Link to="/art-design">Art — Design</Link>
                </li>
              </ul>
            </nav>
            <nav className={footerNav}>
              <h3>Story</h3>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
              </ul>
            </nav>
            <nav className={footerNav}>
              <h3>Get in touch</h3>
              <ul>
                <li>
                  <a
                    href="https://www.instagram.com/studio.kathan/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </nav>
          </section>
        </section>
        <section className={footerRow}>
          <nav className={footerLegal}>
            <Link to="/privacy-notice">Privacy notice</Link>
            <span>|</span>
            <Link to="/cookie-policy">Cookie policy</Link>
          </nav>
          <div className={footerLogoWrapper}>
            <Link to="/" className={footerLogo}>
              <Logo />
            </Link>
            <p className={footerLogoCopy}>
              All rights reserved &copy; {currentYear}
            </p>
          </div>
        </section>
      </footer>
    </section>
  )
}
