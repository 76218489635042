import React from "react"
import { Helmet } from "react-helmet"

export default function Head() {
  return (
    <Helmet
      key="app-head"
      titleTemplate="Studio KATHAN"
      defaultTitle="Studio KATHAN"
    >
      <html lang="en" />

      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      {/* <!-- Primary Meta Tags --> */}
      <title>Studio KATHAN — photography and design studio</title>
      <meta
        name="title"
        content="Studio KATHAN  — photography and design studio"
      />
      <meta
        name="description"
        content="Antwerp based Artist. Photographer.  Design. Fabrics. Product. Graphic. Art. all round"
      />
      <meta name="apple-mobile-web-app-title" content="Studio KATHAN" />
      <meta name="application-name" content="Studio KATHAN" />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://studiokathan.com/" />
      <meta
        property="og:title"
        content="Studio KATHAN  — photography and design studio"
      />
      <meta
        property="og:description"
        content="Antwerp based Artist. Photographer.  Design. Fabrics. Product. Graphic. Art. all round"
      />
      <meta
        property="og:image"
        content="https://studiokathan.com//StudioKATHAN_share.jpg"
      />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://studiokathan.com/" />
      <meta
        property="twitter:title"
        content="Studio KATHAN  — photography and design studio"
      />
      <meta
        property="twitter:description"
        content="Antwerp based Artist. Photographer.  Design. Fabrics. Product. Graphic. Art. all round"
      />
      <meta
        property="twitter:image"
        content="https://studiokathan.com//StudioKATHAN_share.jpg"
      />
    </Helmet>
  )
}
