import React from "react"

export default function TriangleIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <polygon points="0,0 0,10 8,5" />
    </svg>
  )
}
